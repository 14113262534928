* {
  font-family: "Noto Sans KR";
  font-weight: 700;
  color: #4e4e59;
  letter-spacing: -0.4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

body {
  position: relative;
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@font-face {
  font-family: "Noto Sans KR";
  font-weight: 300;
  src: url("./fonts/NotoSansKR-Light.otf") format("woff2"),
    url("./fonts/NotoSansKR-Light.otf") format("woff"),
    url("./fonts/NotoSansKR-Light.otf") format("truetype");
}

@font-face {
  font-family: "Noto Sans KR";
  font-weight: 400;
  src: url("./fonts/NotoSansKR-Regular.otf") format("woff2"),
    url("./fonts/NotoSansKR-Regular.otf") format("woff"),
    url("./fonts/NotoSansKR-Regular.otf") format("truetype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-weight: 500;
  src: url("./fonts/NotoSansKR-Medium.otf") format("woff2");
}

@font-face {
  font-family: "Noto Sans KR";
  font-weight: 700;
  src: url("./fonts/NotoSansKR-Bold.otf") format("woff2"),
    url("./fonts/NotoSansKR-Bold.otf") format("woff"),
    url("./fonts/NotoSansKR-Bold.otf") format("truetype");
}
